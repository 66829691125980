@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,300;0,400;1,100&family=Bree+Serif&family=Comforter+Brush&family=Indie+Flower&family=Montserrat+Subrayada:wght@400;700&family=Poppins:ital,wght@0,600;1,100;1,200&family=Quicksand:wght@500;700&display=swap");
.backgroundForSingleAnime {
  background-image: url("../../../public/images/Sprinkle (3).svg");
  background-position: center;
  background-size: cover;
}

.singleAnimeTitle {
  font-family: "Quicksand", sans-serif;
  color: rgb(240, 207, 20);
  text-shadow: -1px 0 rgb(90, 18, 80), 0 1px rgb(55, 18, 187), 1px 0 rgb(0, 2, 3), 0 -1px rgb(31, 49, 131);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.singleAnimeGenres {
  color: rgb(240, 126, 206);
}

.singleAnimeBlurContainer {
  background: rgba(11, 1, 49, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(199, 95, 240, 0.42);
}