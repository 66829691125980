.heroSmallRightSideUpNext {
  /* From https://css.glass */
  background: rgba(168, 117, 226, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(94, 92, 92, 0.42);
}

.upNextImage {
  border: 1px solid rgb(170, 102, 197);
}

.bigSliderImages {
  border-radius: 6px;
}