@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,300;0,400;1,100&family=Bree+Serif&family=Comforter+Brush&family=Indie+Flower&family=Montserrat+Subrayada:wght@400;700&family=Poppins:ital,wght@0,600;1,100;1,200&family=Quicksand:wght@500;700&display=swap');

@font-face {
  font-family: "fa-brand";
  src: local("fa-solid-900"),
   url("./fonts/SuperaGothic-Book.otf") format("otf");
  // font-weight: bold;
  }

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button{
  appearance: none;
}



.bounce-custom{
  animation: gelatine 0.5s 
}

@keyframes gelatine {
  from,
  to{
    transform: scale(1,1)
  }
  25%{
    transform: scale(0.9,1.1)
    
  }
  50%{
    
    transform: scale(1.1,0.9)
  }
  75%{
    transform: scale(0.95,1.05)

  }
}

.animate-scale {
  transform-origin: 100% 0%;
  animation: scale 0.2s ;
}
.animate-scale-reverse {
  transform-origin: 100% 0%;
  animation: scale 0.2s reverse forwards;
}

@keyframes scale {
  0%{
    transform: scaleY(0)
  }
  100%{
    transform: scaleY(1)
  }
}



.custom-scroll-bar::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.custom-scroll-bar::-webkit-scrollbar-track {
  background: #303030; 
}
 
/* Handle */
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: rgb(223, 221, 221); 
  border-radius: 10em;
  
}

/* Handle on hover */
.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: rgb(160, 86, 245); 
}

/*! For Slide Show */

.slide-in-from-right{
  animation: slideInFromRight  0.5s ease-in;

}
.slide-out-to-left{
  animation: slideOutToLeft  0.5s ease-in;

}
.slide-out-to-right{
  animation:  slideOutToRight  0.5s ease-in;

}
.slide-in-from-left{
  animation: slideInFromLeft   0.5s ease-in;

}

@keyframes slideInFromRight {
  from{
    // transform:translateX(100%)
    transform:translateX(100%)
  }
  to{
    transform:translateX(0%)

  }
}
@keyframes slideInFromLeft {
  from{
    transform:translateX(-100%)
  }
  to{
    transform:translateX(0%)

  }
}
@keyframes slideOutToLeft  {
  from{
    transform:translateX(0%)
  }
  to{
    transform:translateX(-100%)

  }
}
@keyframes slideOutToRight  {
  from{
    transform:translateX(0%)
  }
  to{
    transform:translateX(100%)

  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;