.burgerMenu {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.burgerMenu span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #d3531a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.burgerMenu span:nth-child(1) {
  top: 0px;
}
.burgerMenu span:nth-child(2) {
  top: 18px;
}
.burgerMenu span:nth-child(3) {
  top: 36px;
}
.burgerMenu .menuOpen span:nth-child(1) {
  top: 18px;
  transform: rotate(135deg);
}
.burgerMenu .menuOpen span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.burgerMenu .menuOpen span:nth-child(3) {
  top: 18px;
  transform: rotate(-135deg);
}

.backgroundImageForNav {
  background-image: url("../../../public/images/Sprinkle (3).svg");
  background-position: center;
  background-size: cover;
}

.backgroundForNav {
  background: rgba(32, 1, 32, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(238, 167, 226, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.navbarAnimation {
  -webkit-animation: roll-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: roll-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@-webkit-keyframes roll-in-blurred-top {
  0% {
    transform: translateY(-800px) rotate(-720deg);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-top {
  0% {
    transform: translateY(-800px) rotate(-720deg);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    filter: blur(0);
    opacity: 1;
  }
}
.bgColor2ndNav {
  background: rgba(70, 3, 55, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(238, 167, 226, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

@-webkit-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.menuText {
  color: rgb(223, 161, 238);
  text-decoration: none;
}
.menuText:hover {
  color: yellow;
}