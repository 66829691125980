#oopss {
    /* background: linear-gradient(-45deg, #150020, #052349);
     */
     background-image: url('../../../public/images/Sprinkle\ \(2\).svg');
    background-position: top;
    background-size: cover;
    min-height: 100vh;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 1.5em;
    z-index: 9999;
  }
  #oopss #error-text {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Shabnam', Tahoma, sans-serif;
    color: #000;
    direction: rtl;
  }
  #oopss #error-text img {
    margin: 85px auto 20px;
    height: 342px;
  }
  #oopss #error-text span {
    position: relative;
    color: white;
    font-size: 3.3em;
    font-weight: 900;
    margin-bottom: 50px;
  }
  #oopss #error-text p.p-a {
    font-size: 19px;
    margin: 30px 0 15px 0;
    color: rgb(245, 180, 59);
  }
  #oopss #error-text p.p-b {
    font-size: 15px;
    color: rgb(245, 180, 59);
  }
  #oopss #error-text .back {
    background: rgb(46, 8, 73);
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-decoration: none;
    margin: 2em auto 0;
    padding: .7em 2em;
    border-radius: 500px;
    /* box-shadow: 0 20px 70px 4px rgba(44, 10, 88, 0.1), inset 7px 33px 0 0px #1f096e; */
    font-weight: 900;
    transition: all 300ms ease;
  }
  #oopss #error-text .back:hover {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
    box-shadow: 0 35px 90px 4px rgba(0, 0, 0, 0.3), inset 0px 0 0 3px #000;
  }