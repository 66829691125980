.TagsInputContainer{
  
    background-color: transparent;
    padding: 0 0.25rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    width: 100%;
    outline: none;
    margin-top: 0.5rem;

}

.TagsInputContainerFocusColor{
    border: 2px solid rgb(69, 176, 226);
}
.TagsInputContainerBlurColor{
    border: 2px solid gray;
}

.TagsInputContainer > span{
    width: 8em;
    padding: 0 0 0 0.4em;
    /* font-size: 14px; */
}

.TagsInputField{
    height: 100%;
    width: 100%;
    background-color: transparent;
    flex-grow: 1;
    /* padding: 3em 0 0; */
    border: none;
    outline: none;
    
}

.TagsInputField:focus{
    outline: none;
    border: transparent;
    --tw-ring-shadow:none

}

.pMargin-0>p{
    margin: 0;
}