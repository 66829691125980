* {
  margin: 0;
}

.sideNavImg {
  background-image: url("../../../public/images/Sprinkle (3).svg");
  background-size: cover;
}

.sideNav {
  background: rgba(27, 0, 27, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

#mbSideNav {
  margin-bottom: 0 !important;
}

.ulNavAdmin li a {
  text-decoration: none;
}