.animeListTvSeries {
  height: 12em;
}

.animeListMoviesBackground {
  background-image: url("../../../public/images/Sprinkle (3).svg");
  background-position: top;
  background-size: cover;
}

.animeMoviesTitle {
  font-family: "Merienda One", cursive;
  font-size: 64px;
  z-index: 23;
}
.animeMoviesTitle span {
  color: rgb(240, 78, 140);
}

.animeListMoviesBackgroundBlur {
  background: rgba(61, 40, 80, 0.25);
  box-shadow: 0 8px 32px 0 rgba(160, 95, 233, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(138, 104, 167, 0.767);
  font-size: 14px;
}
.animeListMoviesBackgroundBlur:hover {
  transform: scale(1.1);
}